<template>
    <main id="liste" v-cloak>
        <!-- A changer par le nom du tiers sur lequel on effectue les opérations de compta -->
        <HeaderTab :title="$t('horse.ajout_cheval')" :swiper_tabs="swiper_tabs" />

        <div id="content">
            <div class="row">
                <div class="col-12">
                    <div>
                        <router-view></router-view>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js";

	export default {
		name: "HorseAjoutGlobal",
		mixins: [Navigation],
		data () {
			return {
                param_tab: '',
                actual_tab: 'searchSire',
                swiper_tabs: [],
                mask_ifce: false
			}
		},

		mounted() {
            this.param_tab = this.$route.params.actual_tab

            this.mask_ifce = this.getConfigIfce()
            this.mask_weatherbys = this.getConfigWeatherbys()

            const tabs = [];

            if(!this.mask_ifce) {
                tabs.push({
                    id: 'searchSire',
                    label: 'horse.recherche_sire',
                    active: false,
                    href : 'SearchSire'
                })
                tabs.push({
                    id: 'microship',
                    label: 'horse.recherche_microship',
                    active: false,
                    href : 'Microship'
                })
                tabs.push({
                    id: 'lotSire',
                    label: 'horse.lot_sire',
                    active: false,
                    href : 'LotSire'
                })
            }

            if(!this.mask_weatherbys) {
                tabs.push({
                        id: 'searchWeatherbys',
                    label: 'horse.recherche_weatherbys',
                    active: false,
                    href : 'SearchWeatherbys'
                })
            }

            tabs.push({
                id: 'manual_add',
                label: 'horse.manual_add',
                active: false,
                href : 'AddManuel'
            });

            this.swiper_tabs = tabs

            if(this.$route.name != this.swiper_tabs[0].href) {
                this.$router.push({name: this.swiper_tabs[0].href})
            }
		},
		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
		}
	}
</script>
